const initialState = {
	planSmart: {},
	inventorySmart: {},
};

const configuratorReducer = (state = initialState, action) => {
	switch (action.type) {
		case "TEST":
			return {
				...state,
				test: "test",
			};
		default:
			return {
				...state,
			};
	}
};

export default configuratorReducer;
