import { useState } from "react";
import { useDispatch } from "react-redux";
import ComponentResolver from "../../Resolver/ComponentResolver";
import "./selectListStyle.css";

export default function SelectList({ data }) {
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState(data.defaultActive);
  const el = data.info.map((item) => {
    return (
      <div
        className={`selectlist-item-container ${
          item.id === activeId ? "selectlist-item-container-active" : ""
        }`}
        key={`${data.componentKey}${item.id}`}
        onClick={() => {
          if (activeId !== item.id) {
            setActiveId(item.id);
            dispatch({
              type: "DEPENDENT_COMPONENTS",
              payload: {
                [`${data.componentKey}_${item.id}`]: true,
                [`${data.componentKey}_${activeId}`]: false,
              },
            });
          }
        }}
        style={{
          ...item.style,
        }}
      >
        {item.tag && (
          <>
            {item.id !== activeId && (
              <div className="selectlist-tag-background"></div>
            )}
            <div className="selectlist-item-container-tag">{item.tag}</div>
          </>
        )}
        <div>
          <ComponentResolver
            pageObject={{ components: item.components }}
            spread={true}
          />
        </div>
      </div>
    );
  });
  return (
    <div className="selectlist-container" style={data.style}>
      {el}
    </div>
  );
}
