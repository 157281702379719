/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useEffect, useMemo } from "react";
import numeral from "numeral";
// eslint-disable-next-line import/no-extraneous-dependencies
import Xarrow from "react-xarrows";
// eslint-disable-next-line import/no-extraneous-dependencies
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// eslint-disable-next-line import/no-extraneous-dependencies
import "react-circular-progressbar/dist/styles.css";
import NorthSharpIcon from "@mui/icons-material/NorthSharp";
import SouthSharpIcon from "@mui/icons-material/SouthSharp";
import ControlPointSharpIcon from "@mui/icons-material/ControlPointSharp";
import RemoveCircleOutlineSharpIcon from "@mui/icons-material/RemoveCircleOutlineSharp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import OpenInNewSharpIcon from "@mui/icons-material/OpenInNewSharp";
import CircularProgress from "@mui/material/CircularProgress";
import { FilterContext } from "./FilterContext";
// eslint-disable-next-line import/no-cycle
import MidComp from "./MidComp";
import KpiPopover from "./KpiPopover";
// import { PLAN_KPIS, extractDataFromSelect } from "../../helpers";
// import { specialCharValueFormatter } from "../../utils/formatters";
import "./style.css";
import nodeMockData from "./nodeMockData.json";

const PRODUCT_CHAR_MAPPER = {
	__ia_char_01: "'",
	__ia_char_02: '"',
	__ia_char_03: "/",
	__ia_char_04: "\\",
	__ia_char_05: "`",
	__ia_char_06: "~",
	__ia_char_07: "!",
	__ia_char_08: "@",
	__ia_char_09: "#",
	__ia_char_10: "$",
	__ia_char_11: "%",
	__ia_char_12: "^",
	__ia_char_13: "&",
	__ia_char_14: "*",
	__ia_char_15: "(",
	__ia_char_16: ")",
	__ia_char_19: "=",
	__ia_char_20: "+",
	__ia_char_21: "{",
	__ia_char_22: "}",
	__ia_char_23: "[",
	__ia_char_24: "]",
	__ia_char_25: "|",
	__ia_char_26: ":",
	__ia_char_27: ";",
	__ia_char_28: "<",
	__ia_char_29: ">",
	__ia_char_30: ",",
	__ia_char_31: ".",
	__ia_char_32: "?",
};

const specialCharValueFormatter = (val) => {
	const { value = "-" } = val;
	if (typeof value === "number") {
		return value;
	}
	// Replace keys with their respective values
	if (!(value?.search(/__ia_char_\d{2}|__IA_CHAR_\d{2}/g) !== -1)) {
		return value;
	}
	const formattedValue = value?.replace(
		/__ia_char_\d{2}|__IA_CHAR_\d{2}/g,
		(match) => {
			const normalizedKey = match?.toLowerCase();
			return PRODUCT_CHAR_MAPPER[normalizedKey] || match;
		}
	);

	return formattedValue;
};

function ChildNode({ node, parentId }) {
	const [expandedNodes, setExpandedNodes] = useState([]);
	const [showLines, setShowLines] = useState(false);
	const [
		filter,
		setFilter,
		treeNode,
		setTreeNode,
		searchNode,
		insertNode,
		topLevelFilterData,
		grossData,
	] = useContext(FilterContext);
	const contextValue = useContext(FilterContext);
	console.log("Avinesh----->  ", contextValue);
	const isExpanded = expandedNodes.includes(node.id);
	const [currentNode, setCurrentNode] = useState(node);
	const [loader, setLoader] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const getRandomArbitrary = (min = 30, max = 100) =>
		(Math.random() * (max - min) + min).toFixed(2);

	const getRandom = (length) =>
		Math.floor(10 ** (length - 1) + Math.random() * 9 * 10 ** (length - 1));

	// eslint-disable-next-line consistent-return
	const toSentenceCase = (str) => {
		const s =
			str &&
			specialCharValueFormatter({ value: str })
				.match(
					/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
				)
				.join(" ")
				.toLowerCase();
		if (str) {
			return s.slice(0, 1).toUpperCase() + s.slice(1);
		}
	};

	// Function to sort the array of objects based on the Current selected KPI key inside the `timeline` object
	function sortBySales(array, kpiKey) {
		// Sort the array in descending order based on `sales`
		array.sort((a, b) => {
			const salesA = a?.timeline[kpiKey] || 0; // If `sales` is null, default to 0
			const salesB = b?.timeline[kpiKey] || 0;
			return salesB - salesA; // Sort in descending order
		});
		return array;
	}

	// Function to search for the current node before an node is expanded to built out the dependency array from root node of tree till the current node
	function findNodePath(root, targetId, path = []) {
		if (!root) return null;

		// Add the current node to the path
		path.push(root.data);

		// If the current node is the target node, return the path
		if (root.data.id === targetId) {
			return path;
		}

		// Search for the target node recursively in the children
		for (const child of root.children) {
			const result = findNodePath(child, targetId, [...path]);
			if (result) return result; // If the target node is found in the child subtree, return the path
		}

		return null; // Node not found in this subtree
	}

	//  Useeffect handling firing og Apply filter button
	useEffect(() => {
		setExpandedNodes([]);
		// setCurrentNode({ ...currentNode, children: [] });
		if (currentNode?.children) {
			setCurrentNode({ ...currentNode, children: [] });
		}
		if (!parentId) {
			setCurrentNode({ ...grossData[0] });
		}
	}, [grossData]);

	const getKpiDataOnFilter = async (item, fil) => {
		const hierarchyPath = findNodePath(treeNode, node?.id);
		setLoader(true);

		// Building out the product and store hierarchy object in the filter payload
		const product_hierarchy = {};
		const store_hierarchy = {};
		if (hierarchyPath && hierarchyPath.length !== 0) {
			// Remove the first element from the array
			hierarchyPath.shift();
		}

		hierarchyPath?.map((hierarchy) => {
			if (hierarchy.category === "product") {
				const { id, level, category, ...restParam } = hierarchy;
				const [[hierarchyKey, selectedValue]] = Object.entries(restParam);
				product_hierarchy[hierarchyKey] = [selectedValue];
			} else {
				const { id, level, category, ...restParam } = hierarchy;
				const [[hierarchyKey, selectedValue]] = Object.entries(restParam);
				store_hierarchy[hierarchyKey] = [selectedValue];
			}
		});

		// const dynamicFilterOptions = {};
		// for (const key in topLevelFilterData?.dynamicFilterValues) {
		// 	const element = topLevelFilterData?.dynamicFilterValues[key];
		// 	if (element?.selectedList?.length) {
		// 		dynamicFilterOptions[key] = extractDataFromSelect(
		// 			element?.selectedList
		// 		);
		// 	}
		// }

		// const payload = {
		// 	aggregation_levels: {
		// 		product_hierarchy:
		// 			fil?.selected[item?.level + 1]?.category === "product" &&
		// 			fil?.selected[item?.level + 1]?.filterKey
		// 				? [fil?.selected[item?.level + 1]?.filterKey]
		// 				: [],
		// 		store_hierarchy:
		// 			fil?.selected[item?.level + 1]?.category === "store" &&
		// 			fil?.selected[item?.level + 1]?.filterKey
		// 				? [fil?.selected[item?.level + 1]?.filterKey]
		// 				: [],
		// 	},
		// 	component: "performance-analyzer",
		// 	filters: {
		// 		plan_timeline: {
		// 			start_week_id:
		// 				topLevelFilterData?.timeline?.date_fw_details?.start_fw,
		// 			end_week_id: topLevelFilterData?.timeline?.date_fw_details?.end_fw,
		// 		},

		// 		timeline: {
		// 			start_week_id:
		// 				topLevelFilterData?.timeline?.date_fw_details?.start_fw,
		// 			end_week_id: topLevelFilterData?.timeline?.date_fw_details?.end_fw,
		// 		},
		// 		compare_timeline: {
		// 			start_week_id:
		// 				topLevelFilterData?.compareToTimeline?.date_fw_details?.start_fw,
		// 			end_week_id:
		// 				topLevelFilterData?.compareToTimeline?.date_fw_details?.end_fw,
		// 		},
		// 		...dynamicFilterOptions,
		// 		product_hierarchy,
		// 		store_hierarchy,
		// 		dc_flag: false,
		// 	},
		// 	kpis: [
		// 		...topLevelFilterData?.kpiList?.map((kpi) => kpi.key),
		// 		...PLAN_KPIS,
		// 	],
		// };

		// const result = await getKpiData(payload);
		let result;
		if (parentId) {
			result = nodeMockData[node?.id];
		} else result = nodeMockData.rootId;
		setLoader(false);
		if (result) {
			// Sort the array of objects on descending order
			const sortedData = sortBySales(result, fil?.selected[0]?.filterKey);
			const levelTemp = filter?.levelOptions?.filter(
				(itm) => itm.priority === item?.level + 1
			);

			const currentNodeChild = sortedData?.map((itm) => ({
				...itm,
				id: itm.id,
				progress: getRandomArbitrary(),
				// eslint-disable-next-line no-unsafe-optional-chaining
				level: node?.level + 1,
				category: levelTemp[0]?.category,
			}));
			currentNodeChild?.map((childNode) => {
				const {
					timeline,
					plan_timeline,
					compare_timeline,
					progress,
					...restParam
				} = childNode;
				// When inserting a new node we are dafulting its next node open state as false.
				const insertedNodeData = { ...restParam, isChildOpen: false };
				insertNode(insertedNodeData, currentNode?.id);
			});
			setCurrentNode({
				...currentNode,
				children: currentNodeChild,
			});
			setFilter(fil);
			setTreeNode({ ...treeNode });
		}
	};

	// Logic to switch the flag for all the childs of the received node in the param.
	const switchChildFlag = (childNode) => {
		if (!isExpanded) {
			childNode.data.isChildOpen = !isExpanded;
		} else {
			childNode.data.isChildOpen = !isExpanded;
			for (const child of childNode.children) {
				child.data.isChildOpen = !isExpanded;
				switchChildFlag(child);
			}
		}
	};

	const toggleNode = (item) => {
		// Logic for updating the isChildOpen flag for the current node depending if its open or closed
		// Searching for the node which is being expanded or collapsed
		const currentToggledNode = searchNode(treeNode, item.id);
		switchChildFlag(currentToggledNode);

		if (expandedNodes.includes(item?.id)) {
			setExpandedNodes(expandedNodes.filter((nd) => nd !== item?.id));
			setFilter({
				...filter,
				toggle: filter.toggle + 1,
			});
			// Updating the tree node so that the flag can be captured in the parent useEffect callback
			setTreeNode({ ...treeNode });
		} else {
			setExpandedNodes([...expandedNodes, item?.id]);
			if (!currentNode.children || !currentNode?.children?.length) {
				const levelTemp = filter?.levelOptions?.filter(
					(itm) => itm.priority === item?.level + 1
				);
				const selectedCurrent = {
					...filter?.selected,
					[item?.level]: {
						...filter?.selected[item?.level],
						value: item[filter?.selected[item?.level]?.filterKey],
					},
					[item?.level + 1]: {
						filterKey: levelTemp[0]?.key,
						value: "",
						category: levelTemp[0]?.category,
						nodeKey: [filter?.selected[0]?.filterKey],
						operator: "",
						levelSelected: levelTemp[0],
					},
				};

				getKpiDataOnFilter(item, {
					...filter,
					selected: selectedCurrent,
					toggle: filter.toggle + 1,
				});
			} else {
				setTreeNode({ ...treeNode });
			}
		}
	};

	useEffect(() => {
		setShowLines(false);
		setTimeout(() => {
			setShowLines(true);
		}, 1);
	}, [filter]);

	const getProgressBackgroundColor = (currNode) => {
		const percValue = (
			((currNode?.timeline[filter?.selected[currNode?.level]?.nodeKey] -
				currNode?.plan_timeline[filter?.selected[currNode?.level]?.nodeKey]) /
				currNode?.plan_timeline[filter?.selected[currNode?.level]?.nodeKey]) *
			100
		).toFixed(0);
		if (parseInt(percValue) < 5 && parseInt(percValue) > -2) {
			return "white";
		}
		if (parseInt(percValue) >= 5) {
			return "#ADD6AD";
		}
		if (parseInt(percValue) <= -2) {
			return "#ffcccb";
		}
		return "white";
	};

	function openNewWindowWithNewPath(newPath) {
		try {
			// Get the current URL
			const currentUrl = window.location.href;

			// Find the index of the last occurrence of '/' in the current URL
			const lastSlashIndex = currentUrl.lastIndexOf("/");

			// Check if the last slash index is valid
			if (lastSlashIndex === -1) {
				throw new Error('Invalid URL format: No "/" found.');
			}

			// Construct the new URL by replacing the portion of the URL before the last '/' with the new path
			const newUrl = currentUrl.substring(0, lastSlashIndex) + newPath;

			// Open the new URL in a new window
			window.open(newUrl, "_blank");
		} catch (error) {
			console.error("Error opening Deepdive window:", error.message);
			// Handle error gracefully (e.g., show an error message to the user)
		}
	}
	// Handler function which opens Deepdive page with respect to clicked Node
	const openDeepdiveModule = (nodeData) => {
		const hierarchyPath = findNodePath(treeNode, nodeData?.id);
		if (hierarchyPath.length !== 0) {
			// Remove the first element from the array
			hierarchyPath.shift();
		}
		const product_hierarchy = [];
		const store_hierarchy = [];

		hierarchyPath?.map((hierarchy) => {
			if (hierarchy.category === "product") {
				const { id, level, category, ...restParam } = hierarchy;
				const [[hierarchyKey, selectedValue]] = Object.entries(restParam);
				product_hierarchy.push({
					label: selectedValue,
					value: selectedValue,
					key: hierarchyKey,
				});
			} else {
				const { id, level, category, ...restParam } = hierarchy;
				const [[hierarchyKey, selectedValue]] = Object.entries(restParam);
				store_hierarchy.push({
					label: selectedValue,
					value: selectedValue,
					key: hierarchyKey,
				});
			}
		});

		const hierarchyLocalStorageObject = {
			product_hierarchy,
			store_hierarchy,
		};
		localStorage.setItem(
			"deepdiveNavigationObject",
			JSON.stringify(hierarchyLocalStorageObject)
		);
		openNewWindowWithNewPath("/deepdive");
	};

	const progressBarValueFormatter = (localCurrentNode, localFilter) => {
		if (localCurrentNode?.progress == 100) return "100%";
		const originalPercentageValue = (
			(localCurrentNode?.timeline.sales / localFilter?.gross?.timeline.sales) *
			100
		).toFixed(2);
		const decimalPointIndexPosition = originalPercentageValue?.indexOf(".");
		if (decimalPointIndexPosition === 1) {
			return `${originalPercentageValue}%`;
		}
		return `${originalPercentageValue.slice(0, -1)}%`;
	};

	const nodeValueFormatter = () => {
		const comparedWith =
			topLevelFilterData?.compareToSelectedRadioValue === "plan"
				? "plan_timeline"
				: "compare_timeline";
		const format = filter?.selected[0]?.kpiFormat;
		switch (topLevelFilterData?.deviationValue?.value) {
			case "absolute":
				const nodeValue =
					currentNode?.timeline?.[filter?.selected[0]?.filterKey];
				if (format.split("")[format.length - 1] === "%") {
					return `${numeral(nodeValue).format("0.00")}%`;
				}
				return numeral(nodeValue).format(format);

			case "absolute_deviation":
				const currentTimelineNodeVal =
					currentNode?.timeline?.[filter?.selected[0]?.filterKey];
				const comparedToTimelineNodeVal =
					currentNode?.[comparedWith]?.[filter?.selected[0]?.filterKey];
				if (format.split("")[format.length - 1] === "%") {
					return `${numeral(
						currentTimelineNodeVal - comparedToTimelineNodeVal
					).format("0.00")}%`;
				}
				return numeral(
					currentTimelineNodeVal - comparedToTimelineNodeVal
				).format(format);

			case "percentage_deviation":
				const currentTimelineNode =
					currentNode?.timeline?.[filter?.selected[0]?.filterKey];
				const comparedToTimelineNode =
					currentNode?.[comparedWith]?.[filter?.selected[0]?.filterKey];
				if (format.split("")[format.length - 1] === "%") {
					return `${numeral(
						currentTimelineNodeVal - comparedToTimelineNodeVal
					).format("0.00")}%`;
				}
				return `${numeral(
					(currentTimelineNodeVal - comparedToTimelineNodeVal) /
						comparedToTimelineNode
				).format("0.00")}%`;
				break;
			default:
				return "-";
		}
	};

	const compareWithSection = () => {
		const comparedWith =
			topLevelFilterData?.compareToSelectedRadioValue === "plan"
				? "plan_timeline"
				: "compare_timeline";
		if (currentNode?.[comparedWith]?.[filter?.selected[0]?.filterKey]) {
			return (
				<span>
					{currentNode?.timeline?.[filter?.selected[0]?.filterKey] >
					currentNode?.[comparedWith]?.[filter?.selected[0]?.filterKey] ? (
						<span style={{ color: "green", fontSize: 13, fontWeight: "bold" }}>
							{(
								((currentNode?.timeline?.[filter?.selected[0]?.filterKey] -
									currentNode?.[comparedWith]?.[
										filter?.selected[0]?.filterKey
									]) /
									currentNode?.[comparedWith]?.[
										filter?.selected[0]?.filterKey
									]) *
								100
							).toFixed(2)}
							%
							<NorthSharpIcon
								sx={{
									display: "inline",
									fontWeight: "bold",
									mx: 0.5,
									fontSize: 12,
								}}
							/>
						</span>
					) : (
						<span
							style={{ color: "#FF474C", fontSize: 13, fontWeight: "bold" }}>
							{(
								((currentNode?.timeline?.[filter?.selected[0]?.filterKey] -
									currentNode?.[comparedWith]?.[
										filter?.selected[0]?.filterKey
									]) /
									currentNode?.[comparedWith]?.[
										filter?.selected[0]?.filterKey
									]) *
								100
							).toFixed(2)}
							%
							<SouthSharpIcon
								sx={{
									display: "inline",
									fontWeight: "bold",
									mx: 0.5,
									fontSize: 12,
								}}
							/>
						</span>
					)}
					<br />
				</span>
			);
		}
		return <div>-</div>;
	};

	const selectedLevelLength = useMemo(
		() => filter?.levelOptions?.length,
		[filter]
	);

	return (
		<div key={currentNode.id} className="tree-node-container">
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
			<div
				id={`node_${currentNode.id}`}
				className={parentId ? `node_${parentId}` : ""}
				style={{}}>
				<div className="node-container">
					<div className="circular-progress-container">
						<CircularProgressbar
							value={(
								(currentNode?.timeline[
									filter?.selected[currentNode?.level]?.nodeKey
								] /
									filter?.gross?.timeline[
										filter?.selected[currentNode?.level]?.nodeKey
									]) *
								100
							).toFixed(2)}
							text={progressBarValueFormatter(currentNode, filter)}
							strokeWidth={10}
							styles={buildStyles({
								rotation: 0,
								strokeLinecap: "round",
								textSize: "24px",
								pathTransitionDuration: 0.25,
								pathColor: `#0074b1`,
								textColor: "#000000",
								trailColor: "#e6e6e6",
								textAlign: "left",
								backgroundColor: getProgressBackgroundColor(currentNode),
							})}
							background
							backgroundPadding={2}
						/>
					</div>
					<div className="node-inner-left-container">
						<span
							style={{
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								display: "inline-block",
								maxWidth: "88px",
							}}>
							{toSentenceCase(
								currentNode[filter?.selected[currentNode.level]?.filterKey]
							)}
						</span>
						<OpenInNewSharpIcon
							onClick={() => openDeepdiveModule(node)}
							sx={{
								display: "inline",
								fontWeight: "bold",
								fontSize: 18,
								color: "#0055AF",
								paddingTop: "5px",
								position: "relative",
								top: -2,
							}}
						/>
						<br />
						<span
							style={{
								margin: 5,
								fontSize: 18,
								fontWeight: 400,
								color: "black",
							}}>
							{nodeValueFormatter()}
							&nbsp;&nbsp;&nbsp;
						</span>
						<span
							style={{ color: "#0055AF", cursor: "pointer" }}
							onClick={() => setShowModal(true)}>
							KPIs
							<ArrowForwardIcon
								sx={{
									height: 13,
									width: 13,
									color: "#0055AF",
									position: "relative",
									top: 3,
								}}
							/>
						</span>
					</div>
					<div className="node-inner-right-container">
						{compareWithSection()}
						{topLevelFilterData?.compareToSelectedRadioValue === "plan"
							? "vs Plan"
							: "vs LY"}
					</div>
					{selectedLevelLength !== node?.level ? (
						<div className="right-icon-container">
							{!isExpanded ? (
								<ControlPointSharpIcon
									onClick={() => toggleNode(currentNode)}
									className="expand-node-icon"
								/>
							) : (
								<RemoveCircleOutlineSharpIcon
									onClick={() => toggleNode(currentNode)}
									className="contract-node-icon"
								/>
							)}
						</div>
					) : null}
				</div>
			</div>
			{showLines && parentId && (
				<Xarrow
					start={parentId ? `node_${parentId}` : ""} // can be react ref
					end={`node_${currentNode.id}`} // or an id
					showHead
					animateDrawing={0.1}
					curveness={0.6}
					strokeWidth={1}
				/>
			)}
			{isExpanded && currentNode.children && (
				<div className="nodes-column-alignment">
					{currentNode.children.map((child) => (
						// child?.name?.includes(
						// 	filter[child.level] ? filter[child.level] : "",
						// ) &&
						<div key={child.id} className="recursive-nodes">
							<MidComp data={child} parentId={currentNode.id} />
						</div>
					))}
				</div>
			)}
			{isExpanded && loader && !currentNode.children && (
				<div className="loader-alignment">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<CircularProgress
						style={{ display: "flex", justifyContent: "center" }}
					/>
				</div>
			)}
			{showModal && (
				<KpiPopover
					kpiData={currentNode}
					name={specialCharValueFormatter({
						value: currentNode[filter?.selected[currentNode.level]?.filterKey],
					})}
					showModal
					setShowModal={setShowModal}
				/>
			)}
		</div>
	);
}

export default React.memo(ChildNode);
