import React from "react";
import "./style.css";

const CardContainer = ({
	className,
	children,
	width = "100%",
	bg = "#fff",
	height = "auto",
	br = "4px",
	pad = "20px",
	zIndex = 1,
	onClick,
	style,
}) => (
	<div
		style={{
			backgroundColor: bg,
			minHeight: height,
			width,
			borderRadius: br,
			padding: pad,
			zIndex,
			boxShadow: "rgba(0, 0, 0, 0.12) 0px 0px 8px",
			boxSizing: "border-box",
			...style,
		}}
		onClick={onClick}>
		{children}
	</div>
);

export default React.memo(CardContainer);
