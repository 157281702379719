import React, { useState } from "react";
import { Grid } from "@mui/material";
import Editor from "./JSONEditor";
import ComponentResolver from "../Home/Resolver/ComponentResolver";

const Main = () => {
  const [textArea, setTextArea] = useState({});
  const [pageConfig, setPageConfig] = useState({});
  const [previewConfig, setPreviewConfig] = useState();
  const [pageTempConfig, setPageTempConfig] = useState({});
  const [loadFullScreen, setLoadFullScreen] = useState(false);

  const handleChange = (e) => {
    setPageConfig(e);
    reloadJson();
  };

  const handleTextAreaChange = (e) => {
    setTextArea(e.target.value);
    reloadJson();
  };

  const importJson = () => {
    setPageTempConfig(JSON.parse(textArea));
    handleChange(JSON.parse(textArea));

    reloadJson();
  };

  const reloadJson = () => {
    setPreviewConfig(null);
    setTimeout(() => {
      setPreviewConfig(pageTempConfig);
    }, 50);
  };

  const onClickFullScreen = () => {
    setLoadFullScreen(!loadFullScreen);
  };

  return (
    <div>
      <h2 style={{ flex: "display", justifyContent: "center" }}>
        JSON Preview Tool
      </h2>
      <Grid container spacing={2}>
        {!loadFullScreen && (
          <Grid item xs={5}>
            <textarea
              style={{ width: "100%", height: 200 }}
              onChange={handleTextAreaChange}
            />
            <button onClick={importJson}>Import Raw Object</button>
            <button onClick={reloadJson}>Preview JSON Object</button>
            <Editor
              style={{ height: 600 }}
              onChange={(data) => setPageTempConfig(data)}
              value={pageConfig}
            />
          </Grid>
        )}
        <Grid item xs={loadFullScreen ? 12 : 7}>
          {previewConfig && (
            <>
              <button onClick={onClickFullScreen}>
                {!loadFullScreen ? "Full Screen View" : "Previous View"}
              </button>
              <ComponentResolver
                pageObject={previewConfig}
                redirectFrom="Home"
              />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default Main;
