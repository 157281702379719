import styled, { css } from "styled-components";

export const PrimaryBlueColor = "#0055AF";
export const SecondaryBlueColor = "#033162";
export const DefaultColorButton = "#ACACAC";
export const LightBgColorButton = "#E5EDF7";
export const CheckBoxBorder = "#394960";
export const AlertGreenSuccessStroke = "#24A148";
export const AlertGreenSuccessBackground = "#E8F5EC";
export const AlertRedErrorStroke = "#DA1E28";
export const AlertRedErrorBackground = "#FCE9EA";
export const DefaultButtonText = "#AFAFAF";
export const AlertOrangeWarningStroke = "#FF832B";
export const AlertOrangeWarningBackground = "#FFF3EA";
export const AlertBlueInfoBackground = "#E5EDF7";
export const AlertBlueInfoStroke = "#0055AF";
export const WhiteColor = "#ffffff";
export const AvatarBgColor = "#EBECF0";
export const BodyFontColor = "#1d1d1d";
export const LabelColor = "#758490";
export const DisabledTextareaBg = "#EFEFEF";
export const FocusDisableBorderColor = "#d4d4d4";
export const DisabledButtonFontColor = "#C7C7C7";
export const TooltipBackgroundColor = "#535B63";
export const TabHoverBackgroundColor = "#E2E4ED";
export const ToggleSwitchActiveBg = "#A4CBFB";
export const ToggleSwitchDeactivateOffBgSlider = "#C1DDFF";
export const ToggleSwitchDeactivateOffBg = "#73AAE5";
export const FocusBackgroundColor = "#E5EDF7";
export const PanelFooterBg = "#EEF0F7";
export const AGInputColor = "#c4c4c4";
export const AgCellRendererBackground = "#F3F9FF";
export const SelectOptionFocusBackground = "#eef5fd";

export const StyledTable = styled.div``;

export const StyledTableContainer = styled.div`
	height: 100%;
	width: 100%;

	.common_table > div {
		font-family: "Poppins", sans-serif !important;
		--ag-font-family: "Poppins", sans-serif !important;
	}

	.ag-header-cell,
	.ag-header-viewport {
		background: ${DisabledTextareaBg};
	}

	.ag-selection-checkbox {
		margin: 0 !important;
	}

	.ag-theme-alpine,
	.ag-theme-alpine-dark {
		--ag-border-color: ${FocusDisableBorderColor};
	}
	.ag-header-cell-resize::after {
		--ag-border-color: ${FocusDisableBorderColor};
		bottom: 0;
		height: auto;
		width: 1px;
		top: 0;
		display: none;
	}

	.ag-cell-label-container {
		padding: 0px;
	}

	.ag-header-row {
		height: 40px !important;
	}
	.ag-header-cell,
	.ag-header-group-cell {
		padding: 0px 12px !important;
		border-right: 1px solid ${FocusDisableBorderColor};
	}

	.ag-ltr .ag-cell {
		border-right: 1px solid ${FocusDisableBorderColor};
	}

	.ag-row {
		border-bottom: 1px solid ${FocusDisableBorderColor};
	}

	.ag-ltr .ag-header-cell-resize {
		right: -5px;
	}
	.ag-header-cell-text {
		font-size: 14px;
		font-weight: 600;
		color: ${BodyFontColor};
	}

	.ag-ltr .ag-cell {
		font-size: 14px;
		color: ${BodyFontColor};
		padding-left: 12px;
	}
	.ag-row-hover {
		--ag-row-hover-color: ${LightBgColorButton};
	}

	.ag-checkbox-input-wrapper.ag-checked::after {
		content: var(--ag-icon-font-code-checkbox-checked, "\f106");
		color: ${PrimaryBlueColor};
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
	}
	.ag-checkbox-input-wrapper::after {
		color: ${DefaultColorButton};
	}

	.ag-theme-alpine,
	.ag-theme-alpine-dark {
		--ag-selected-row-background-color: ${LightBgColorButton};
	}
	.ag-icon.ag-icon-menu::before {
		content: "";
		width: 5px;
		height: 15px;
		background-size: 5px;
		display: block;
		cursor: pointer;
	}

	.ag-pinned-left-cols-container,
	.ag-pinned-right-cols-container {
		// z-index: 9999;
		box-shadow: 0px 3px 4px #00000029;
	}

	.ag-header-active {
		--ag-selected-row-background-color: ${LightBgColorButton} !important;
	}

	.ag-menu-option-active:not(.format_main_container, .settings_main_container) {
		background: ${LightBgColorButton} !important;
		cursor: pointer;
	}
	.ag-menu-option-active {
		background: none;
	}

	.ag-row-selected {
		--ag-selected-row-background-color: ${LightBgColorButton} !important;
	}
	.ag-text-field-input {
		border: 1px solid ${AGInputColor} !important;
		border-radius: 4px !important;
		padding: 4px 12px;
		padding-right: 31px;
		height: 29px;
		box-sizing: border-box;
		background-size: 16px;
		font-size: 14px !important;
		background-position: center right 11px;
	}
	.ag-icon-tree-closed {
		transform: rotate(0deg);
	}
	.ag-icon:hover {
		color: ${PrimaryBlueColor} !important;
	}
	.ag-details-row {
		padding: 16px;
	}
	.ag-row.ag-row-footer {
		background: ${PanelFooterBg} !important;
	}

	.ag-tabs-header {
		display: none;
	}
	.ag-menu-option-icon {
		padding-left: 10px;
		width: 10px;
	}
	.ag-menu-option-shortcut {
		padding: 0;
		width: 0;
	}
	.ag-icon-tick::before {
		padding-right: 4px;
		width: 4px;
	}
	.settings_main_container .ag-menu-option-text {
		width: 14px;
		height: 14px;
		background-size: 14px;
		background-position: 0 center;
		padding-left: 20px;
		color: ${CheckBoxBorder};
		font-weight: 600;
	}
	.format_main_container .ag-menu-option-text {
		width: 14px;
		height: 14px;
		background-size: 14px;
		background-position: 0 center;
		padding-left: 20px;
		color: ${CheckBoxBorder};
		font-weight: 600;
	}
	.ag-theme-alpine .ag-menu {
		background-color: ${WhiteColor};
	}

	.ag-menu-option-popup-pointer {
		display: flex;
		justify-content: flex-end;
	}

	.ag-theme-alpine .ag-side-buttons {
		width: 24px;
	}
	.ag-virtual-list-item {
		height: 26px !important;
	}
	.ag-theme-alpine .ag-side-buttons {
		width: 24px;
		padding-top: 0px;
	}
	.ag-side-button-button {
		justify-content: flex-start;
	}
	.ag-theme-alpine .ag-side-button-button {
		color: ${LabelColor};
	}
	.ag-theme-alpine .ag-side-button-button:hover {
		color: ${PrimaryBlueColor};
	}
	.ag-pinned-left-cols-container,
	.ag-pinned-right-cols-container {
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12) !important;
		//z-index: 1 !important;
	}

	.ag-pinned-left-cols-container {
		border-right: 1px solid #d4d4d4;
	}
	.ag-pinned-right-cols-container {
		border-left: 1px solid #d4d4d4;
	}
	.ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(
			.ag-cell-range-single-cell
		) {
		border-left: none;
	}
	.ag-theme-alpine .ag-side-buttons,
	.ag-theme-alpine-dark .ag-side-buttons,
	.ag-side-buttons {
	}
	.ag-side-buttons {
		width: 24px !important;
		padding: 0px;
	}
	.ag-side-button {
	}
	button.ag-side-button-button {
		padding: 0px;
		min-height: auto;
		padding: 8px 0;
	}
	.ag-side-button-icon-wrapper {
		margin-bottom: 0px;
	}

	.ag-side-button-button:hover,
	.ag-selected .ag-side-button-button {
		color: ${PrimaryBlueColor} !important;
	}

	.ag-ltr .ag-side-bar-right .ag-selected .ag-side-button-button {
		border-left-color: #fff;
	}

	.ag-tool-panel-wrapper {
		position: absolute;
		width: 241px;
		right: 24px;
		top: 0;
		bottom: 0;
		background: #fff;
		border-left: 1px solid #d4d4d4;
		box-shadow: -4px 0px 4px 0px rgba(0, 0, 0, 0.08);
		overflow: auto;
		padding: 8px 12px;
		box-sizing: border-box;
	}

	.ag-header-expand-icon:hover {
		color: ${PrimaryBlueColor};
	}

	.ag-menu-list {
	}

	.ag-set-filter-item {
		margin: 0px;
	}
	.ag-mini-filter {
		margin: 16px 0 10px 0;
	}

	.ag-row-odd {
		background: ${WhiteColor} !important;
	}

	.number-header * .ag-cell-label-container {
		display: flex;
		flex-direction: row;

		.ag-header-cell-label {
			flex-direction: row-reverse;
		}
	}

	.number-cell {
		text-align: right;
	}

	.cell-renderer {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: ${AgCellRendererBackground};
	}
`;
