import axios from "axios";
import apiJson from "./clientsList";
import Cookies from "js-cookie";

const APP_BASE_URL = process.env.REACT_APP_API_ENDPOINT;
let headers = {};
if (Cookies.get("authToken")) {
	headers = {
		authorization: `${Cookies.get("authToken")}`,
	};
}
const callApi = axios.create({
	baseURL: APP_BASE_URL,
	// baseURL: "https://sandbox.products.impactsmartsuite.com/common/api/v1",
	// baseURL: "https://dev-ui-bkp.products.impactsmartsuite.com/ui-enhancement/api/v1/",
	withCredentials: true,
	headers: headers,
});

console.log("Window loc ==>", window.location);

callApi.interceptors.request.use(
	function (config) {
		return config;
	},
	function (error) {
		console.log("request fails with error", error.message);
		return Promise.reject(error);
	}
);

callApi.interceptors.response.use(
	function (res) {
		return res;
	},
	async function (error) {
		const {
			response: { status },
		} = error;
		console.log(error, status);
		console.log("response fails with error", error.message, error.config);
		return error?.response?.data;
	}
);

export default callApi;
