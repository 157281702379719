import numeral from "numeral";

const numFormatter = (num, format, invMap = false) => {
	// if (!num?.value) return '-';
	const mappingFront = {
		Transactions: "",
		"Sales Units": "",
		"Basket Size": "",
		"Gross Revenue": "$",
		AUR: "$",
		"Basket Value": "$",
		Margin: "$",
		AUM: "$",

		"Promo Sales Units": "",
		"Promo Revenue": "$",
		"Promo Margin": "$",

		"Discount Sales Units": "",
		"Discount Revenue": "$",
		"Discount Margin": "$",
		"Inventory (EOP)": "",

		transactions: "",
		quantity: "",
		avg_basket_size: "",
		sales: "$",
		aur: "$",
		avg_basket_value: "",
		margin: "$",
		aum: "$",
		promo_units: "",
		promo_sales: "$",
		promo_margin: "$",
		discount_units: "",
		discount_sales: "$",
		discount_margin: "$",
		inv_eop: "",
	};

	const mappingBack = {
		"Effective Promo %": "%",
		"Overall Discount %": "%",
		stockout_perc: "%",
		"Stock Out %": "%",
		"Gross Margin%": "%",
	};

	const mappingWeather = {
		snowdepth: " inches",
		max_temp: " F",
		precipitation: " mm",
	};

	const number = num.value != null ? Math.abs(num.value) : Math.abs(num);

	if (format === "Basket Size") {
		return (
			(mappingFront[format] ? mappingFront[format] : "") + number.toFixed(2)
		);
	}
	if (format === "AUR" || format === "AUM" || format === "Basket Value") {
		return (
			(mappingFront[format] ? mappingFront[format] : "") + number.toFixed(1)
		);
	}
	if (
		format === "Effective Promo %" ||
		format === "Overall Discount %" ||
		format === "Stock Out %" ||
		format === "Gross Margin%"
	) {
		return mappingBack[format] ? number.toFixed(1) + mappingBack[format] : "";
	}

	if (
		format === "snowdepth" ||
		format === "max_temp" ||
		format === "precipitation"
	) {
		return mappingWeather[format]
			? number.toFixed(2) + mappingWeather[format]
			: "";
	}

	if (format === "percentageOne") {
		return `${number.toFixed(1)}%`;
	}

	if (number > 999 && number <= 1000000) {
		return `${
			(mappingFront[format] ? mappingFront[format] : "") +
			numeral(number / 1000).format("0,0.[0]")
		} K`; // convert to K for number from > 1000 < 1 million
	}
	if (number > 1000000 && number <= 1000000000) {
		return `${
			(mappingFront[format] ? mappingFront[format] : "") +
			numeral(number / 1000000).format("0,0.[0]")
		} M`; // convert to M for number from > 1 million
	}
	if (number > 1000000000) {
		return `${
			(mappingFront[format] ? mappingFront[format] : "") +
			numeral(number / 1000000000).format("0,0.[0]")
		} B`;
	}
	if (number < 999) {
		if (number === 0 && invMap) {
			return mappingFront[format] ? mappingFront[format] : "";
		}
		return (
			(mappingFront[format] ? mappingFront[format] : "") + number.toFixed()
		); // if value < 1000, nothing to do
	}
};

export default {
	columnDefs(formatterMapperObject = {}) {
		// Null Value Formatter
		const finiteValueFormatter = (val) => {
			if (val?.value === "-") return "-";
			return `${val?.value}%`;
		};
		const numeralValueFormatter = (val) => {
			if (val?.value === null || val?.value === "-") return "-";
			// return numeral(val?.value)?.format(formatterMapperObject[val?.data?.label]?.format);
			return numFormatter(val?.value, val?.data?.label);
		};
		// Returning Final Column Definitions from this file
		return [
			{
				field: "label",
				headerName: "KPI",
				minWidth: 150,
				headerClass: "bold-header",
			},
			{
				field: "act",
				headerName: "ACT",
				headerClass: "ag-right-aligned-header",
				type: "rightAligned",
				minWidth: 180,
				valueFormatter: numeralValueFormatter,
			},
			{
				field: "plan_var",
				headerName: "%Plan Variance",
				headerClass: "ag-right-aligned-header",
				type: "rightAligned",
				minWidth: 160,
				cellClassRules: {
					"rag-red": (params) =>
						formatterMapperObject[params?.data?.label]?.minThreshold &&
						params.value &&
						params.value <
							formatterMapperObject[params?.data?.label]?.minThreshold * 100,
					"rag-green": (params) =>
						formatterMapperObject[params?.data?.label]?.maxThreshold &&
						params.value &&
						params.value >=
							formatterMapperObject[params?.data?.label]?.maxThreshold * 100,
				},
				valueFormatter: finiteValueFormatter,
			},
			{
				field: "timeline_var",
				headerName: "%LY Variance",
				headerClass: "ag-right-aligned-header",
				type: "rightAligned",
				minWidth: 160,
				cellClassRules: {
					"rag-red": (params) =>
						formatterMapperObject[params?.data?.label]?.minThreshold &&
						params.value &&
						params.value <
							formatterMapperObject[params?.data?.label]?.minThreshold * 100,
					"rag-green": (params) =>
						formatterMapperObject[params?.data?.label]?.maxThreshold &&
						params.value &&
						params.value >=
							formatterMapperObject[params?.data?.label]?.maxThreshold * 100,
				},
				valueFormatter: finiteValueFormatter,
			},
		];
	},
};
