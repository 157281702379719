import React, { useState, useEffect, useRef } from "react";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import { transform } from "ol/proj";
import { toStringXY } from "ol/coordinate";
import GeoJSON from "ol/format/GeoJSON";
import mapdata from "../data/mock-geojson-api.json";
import * as ol from "ol";

export function MapWrapper() {
  //###################################################################
  const [features, setFeatures] = useState([]);

  // initialization - retrieve GeoJSON features from Mock JSON API get features from mock
  //  GeoJson API (read from flat .json file in public directory)

  //###################################################################
  const [map, setMap] = useState();
  const [featuresLayer, setFeaturesLayer] = useState();
  const [selectedCoord, setSelectedCoord] = useState();
  const mapElement = useRef();
  const mapRef = useRef();
  mapRef.current = map;
  useEffect(() => {
    const data = mapdata;
    const wktOptions = {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    };
    const parsedFeatures = new GeoJSON().readFeatures(data, wktOptions);
    setFeatures(parsedFeatures);

    const initalFeaturesLayer = new VectorLayer({
      source: new VectorSource(),
    });
    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        // Google Maps Terrain
        new TileLayer({
          source: new XYZ({
            url: "http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}",
          }),
        }),

        initalFeaturesLayer,
      ],
      view: new View({
        projection: "EPSG:3857",
        center: [0, 0],
        zoom: 2,
      }),
      controls: [],
    });
    initialMap.on("click", handleMapClick);
    setMap(initialMap);
    setFeaturesLayer(initalFeaturesLayer);
  }, []);
  useEffect(() => {
    if (features.length) {
      featuresLayer.setSource(
        new VectorSource({
          features: features,
        })
      );
      map.getView().fit(featuresLayer.getSource().getExtent(), {
        padding: [100, 100, 100, 100],
      });
    }
  }, [features]);
  const handleMapClick = (event) => {
    const clickedCoord = mapRef.current.getCoordinateFromPixel(event.pixel);
    const transormedCoord = transform(clickedCoord, "EPSG:3857", "EPSG:4326");
    setSelectedCoord(transormedCoord);
  };
  return (
    <div>
      <div ref={mapElement} className="map-container"></div>
      <div className="clicked-coord-label">
        <p>{selectedCoord ? toStringXY(selectedCoord, 5) : ""}</p>
      </div>
    </div>
  );
}

export default MapWrapper;
