import React, { useState, useEffect, useMemo } from "react";
import LanguageTree from "./LanguageTree";
import CardContainer from "./cardContainer";
import { FilterContext } from "./FilterContext";
import PopupImage from "./PopupImage";
// import ModuleLevelLoader from "../../commonComponents/ModuleLevelLoader";
import "./style.css";
// import "../decompositionTreeFilter/style.css";
import mockData from "./mockData.json";

function Tree({ filterData = mockData?.propFilterData }) {
	// State variable storing the list of strings of header labels in the tree at each moment
	const [treeLabelList, setTreeLabelList] = useState([]);
	const [grossData, setGrossData] = useState(mockData.contextGrossData);
	const [levelOptions, setLevelOptions] = useState([]);
	const [filter, setFilter] = useState(mockData.contextFilterData);
	const [isModuleLevelLoaderActive, setIsModuleLevelLoaderActive] =
		useState(true);

	// Setting up the treeNode node variable for n-ary tree implementation
	const [treeNode, setTreeNode] = useState(null);

	// Storing the top level selected filters along with timeline and compared to timeline response so that it does not need to be fetched everytime a new node is opened
	const [topLevelFilterData, setTopLevelFilterData] = useState(
		mockData.contextTopLevelFilterData
	);

	// Function to assign priority to the dynamic levels
	function assignPriority(obj) {
		const keys = Object.keys(obj);
		const result = [];
		keys.forEach((key, index) => {
			obj[key].forEach((item) => {
				result.push({ ...item, priority: index + 1 });
			});
		});
		return result;
	}

	const getKpiDataOnFilter = async () => {
		setIsModuleLevelLoaderActive(true);
		// Building the Level options array
		const dynamicLevelArray = assignPriority(filterData?.levels);
		setLevelOptions(dynamicLevelArray);

		// Fetching the start week id and end week id for selected timeline
		try {
			// Fetching the start week id and end week id for Compared To timeline.
			/* Currently passing the firsst parameter that is selected year as null since we dont have that in our UI */

			// When inserting a Iniital node we are dafulting its next node open state as false.
			const insertedNodeData = mockData.insertedNodeData;
			insertNode(insertedNodeData);
			setIsModuleLevelLoaderActive(false);
		} catch (err) {
			console.log("Error fetching Inital Node data", err);
			setIsModuleLevelLoaderActive(false);
		}
	};

	useEffect(() => {
		if (Object.keys(filterData)?.length) {
			getKpiDataOnFilter();
		}
	}, [filterData]);

	// N-ary tree Generic callback functions

	// Method to search for a node in the tree based on its id
	function searchNode(node, id) {
		if (!node) return null;

		if (node.data.id === id) {
			return node;
		}

		for (const child of node.children) {
			const result = searchNode(child, id);
			if (result) return result;
		}

		return null; // Node not found
	}

	// Method to insert a new node into the tree
	function insertNode(data, parentId = null) {
		const newNode = { data, children: [] };
		if (!treeNode || !parentId) {
			setTreeNode(newNode);
			return;
		}

		const parentNode = searchNode(treeNode, parentId);
		if (!parentNode) {
			console.error("Parent node not found");
			return;
		}
		// Checking that to be inserted node should not be present already in the array. If present, then dont push otherwise push
		const isNodeAlreadyPresent = parentNode.children?.some(
			(node) => node.data.id === newNode.data.id
		);
		if (!isNodeAlreadyPresent) {
			parentNode.children.push(newNode);
		}
		/* Removing setTreeNode from here since we dont want to update the whole tree on each insert. We will only update the treeNode when all the nodes are being inserted */
	}

	/**
	 * Function to find the maximum depth in a tree where the isChildOpen key is true.
	 * @param {Object} treeNode - The root node of the tree.
	 * @param {number} depth - The current depth, defaults to 0.
	 * @returns {number} - The maximum depth where isChildOpen is true.
	 */
	function getMaxDepth(node, depth = 0) {
		// Base case: if the current node's isChildOpen is false, return the current depth
		if (!node.data.isChildOpen) {
			return depth;
		}

		// Initialize max depth as the current depth
		let maxDepth = depth;

		// Recursive call for all children
		if (node.children) {
			for (const child of node.children) {
				maxDepth = Math.max(maxDepth, getMaxDepth(child, depth + 1));
			}
		}

		return maxDepth;
	}

	// Function to run everytime treeNode changes. This is required to develop the top level labels above the tree depending on the number of levels opened at any particular time.
	useEffect(() => {
		if (treeNode) {
			const maxDepthTree = getMaxDepth(treeNode, 0);
			// Generating logic and setting state for the levels label at top of tree
			const extractedLevels = levelOptions?.slice(0, maxDepthTree);
			const treeLevelLabels = extractedLevels?.map(
				(level, index) => `Level ${index + 1} : ${level?.label}`
			);
			setTreeLabelList(treeLevelLabels);
		}
	}, [treeNode]);

	// Memoizing the to be passed context array
	const contextValueList = useMemo(
		() => [
			filter,
			setFilter,
			treeNode,
			setTreeNode,
			searchNode,
			insertNode,
			mockData.contextTopLevelFilterData,
			mockData.contextGrossData,
		],
		[
			filter,
			setFilter,
			treeNode,
			setTreeNode,
			searchNode,
			insertNode,
			topLevelFilterData,
			grossData,
		]
	);

	return (
		<div className="col col-1">
			<CardContainer height="200px">
				<div className="section-top">
					<div className="left-container">
						<div className="section-heading">Decomposition Tree</div>
						<span>
							<PopupImage />
						</span>
					</div>
				</div>
				<div className="section-bottom">
					<div className="relative-pos">
						{/* <ModuleLevelLoader active={isModuleLevelLoaderActive} /> */}
						<div className="Tree">
							<div className="tree-filter-label-container">
								<div style={{ marginLeft: "255px" }} />
								{treeLabelList.map((label) => (
									<div className="tree-filter-label">{label}</div>
								))}
							</div>
							{grossData?.length ? (
								<FilterContext.Provider value={contextValueList}>
									<LanguageTree data={mockData.contextGrossData} />
								</FilterContext.Provider>
							) : null}
						</div>
					</div>
				</div>
			</CardContainer>
		</div>
	);
}

export default React.memo(Tree);
