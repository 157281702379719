import React, { useRef, useEffect } from "react";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";

export default function JsonEditor({ value = {}, onChange }) {
	const jsonEditorRef = useRef(null);

	useEffect(() => {
		if (jsonEditorRef.current !== null) {
			jsonEditorRef.current.set(value);
		}
	}, [value]);

	const setRef = (instance) => {
		if (instance) {
			jsonEditorRef.current = instance.jsonEditor;
		} else {
			jsonEditorRef.current = null;
		}
	};

	return <Editor ref={setRef} value={value} onChange={onChange} />;
}
