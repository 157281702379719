import { Box } from "@mui/material";
const Text = ({
  children,
  weight = "Medium",
  size = "m",
  variant = "body1",
  sx,
  style,
  color,
  ...props
}) => {
  return (
    <Box
      color={color}
      {...props}
      style={style}
      sx={{
        ...sx,
        fontWeight: weight,
      }}
      variant={variant}
    >
      {children}
    </Box>
  );
};
export default Text;
