import React, { useEffect, useMemo, useState, useRef } from "react";
import Modal from "./ModalContainer";
import decompositionTreeKpiColDefs from "./decompositionTreeKpiColDefs";
import DataTable from "./DataTable";

const kpiList = {
	avg_discount_perc: {
		display_name: "Overall Discount %",
		sort_order: 10,
		format: "0.00%",
		min_thresold: -0.02,
		max_thresold: 0.02,
		metric_type: "sales",
	},
	discount_sales: {
		display_name: "Discount Revenue",
		sort_order: 7,
		format: "$0.0a",
		min_thresold: -0.02,
		max_thresold: 0.02,
		metric_type: "sales",
	},
	discount_units: {
		display_name: "Discount Sales Units",
		sort_order: 8,
		format: "0.0a",
		min_thresold: -0.02,
		max_thresold: 0.02,
		metric_type: "sales",
	},
	discount_margin: {
		display_name: "Discount Margin",
		sort_order: 9,
		format: "$0.0a",
		min_thresold: -0.02,
		max_thresold: 0.02,
		metric_type: "sales",
	},
	avg_basket_value: {
		display_name: "Basket Value",
		sort_order: 12,
		format: "$0.0a",
		min_thresold: -0.02,
		max_thresold: 0.02,
		metric_type: "sales",
	},
	avg_basket_size: {
		display_name: "Basket Size",
		sort_order: 13,
		format: "0.0a",
		min_thresold: -0.02,
		max_thresold: 0.02,
		metric_type: "sales",
	},
	transactions: {
		display_name: "Transactions",
		sort_order: 11,
		format: "0.0a",
		min_thresold: -0.02,
		max_thresold: 0.02,
		metric_type: "sales",
	},
	freshness: {
		display_name: "Freshness",
		sort_order: 15,
		format: "0.0a",
		min_thresold: -0.02,
		max_thresold: 0.02,
		metric_type: "inventory",
	},
	inv_eop: {
		display_name: "Inventory (EOP)",
		sort_order: 14,
		format: "0.0a",
		min_thresold: null,
		max_thresold: null,
		metric_type: "inventory",
	},
	margin: {
		display_name: "Margin",
		sort_order: 4,
		format: "$0.0a",
		min_thresold: -0.02,
		max_thresold: 0.02,
		metric_type: "sales",
	},
	quantity: {
		display_name: "Sales Units",
		sort_order: 2,
		format: "0.0a",
		min_thresold: -0.02,
		max_thresold: 0.02,
		metric_type: "sales",
	},
	sales: {
		display_name: "Gross Revenue",
		sort_order: 1,
		format: "$0.0a",
		min_thresold: -0.02,
		max_thresold: 0.02,
		metric_type: "sales",
	},
	gross_margin_perc: {
		display_name: "Gross Margin%",
		sort_order: 6,
		format: "0.00%",
		min_thresold: -0.02,
		max_thresold: 0.02,
		metric_type: "sales",
	},
	aum: {
		display_name: "AUM",
		sort_order: 5,
		format: "$0.0a",
		min_thresold: -0.02,
		max_thresold: 0.02,
		metric_type: "sales",
	},
	aur: {
		display_name: "AUR",
		sort_order: 3,
		format: "$0.0a",
		min_thresold: -0.02,
		max_thresold: 0.02,
		metric_type: "sales",
	},
};

function DataPopover({ setShowModal, kpiData, name, showModal }) {
	const [rowData, setRowData] = useState([]);
	const [colDefs, setColDefs] = useState();

	const gridRef = useRef();

	const toSentenceCase = (str) => {
		const s =
			str &&
			str
				.match(
					/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
				)
				.join(" ")
				.toLowerCase();
		if (str) {
			return s.slice(0, 1).toUpperCase() + s.slice(1);
		}
	};

	// Developing a Label and key reverse mapper Object
	const reverseMapper = useMemo(() => {
		const labelMapper = Object.keys(kpiList)?.reduce((acc, kpiKey) => {
			const kpiInfo = kpiList[kpiKey];
			acc[kpiInfo.display_name] = {
				key: kpiKey,
				format: kpiInfo.format,
				minThreshold: kpiInfo.min_thresold,
				maxThreshold: kpiInfo.max_thresold,
				order: kpiInfo.sort_order,
			};
			return acc;
		}, {});
		return labelMapper;
	}, [kpiList]);

	useEffect(() => {
		const data = [];
		if (kpiData) {
			// eslint-disable-next-line camelcase
			const { timeline, plan_timeline, compare_timeline } = kpiData;

			Object.keys(timeline).map((itm) => {
				if (typeof timeline[itm] !== "string") {
					data.push({
						label: kpiList?.[itm]?.display_name,
						act: timeline[itm] ? timeline[itm]?.toFixed(2) : "-",
						// eslint-disable-next-line camelcase
						plan_var:
							timeline[itm] && plan_timeline[itm]
								? (
										((timeline[itm] - plan_timeline[itm]) /
											plan_timeline[itm]) *
										100
								  )?.toFixed(2)
								: "-",
						// eslint-disable-next-line camelcase
						timeline_var:
							timeline[itm] && compare_timeline[itm]
								? (
										((timeline[itm] - compare_timeline[itm]) /
											compare_timeline[itm]) *
										100
								  )?.toFixed(2)
								: "-",
					});
				}
			});
		}
		// Sorting the row Data with respect to the specifies sort_order from the backend
		const orderArray = Object.entries(reverseMapper);

		// Sort the data array based on the order specified in the orderArray
		data.sort((a, b) => {
			// Find the order of each label in the orderArray
			const orderA = orderArray.find(([label, _]) => label === a.label);
			const orderB = orderArray.find(([label, _]) => label === b.label);

			// If order is not defined for any label, keep it at the end
			if (!orderA) return 1;
			if (!orderB) return -1;

			// Compare the order of labels
			return orderA[1].order - orderB[1].order;
		});
		setColDefs(decompositionTreeKpiColDefs?.columnDefs(reverseMapper));

		setRowData(data);
	}, [kpiData]);

	return (
		<Modal
			size="medium"
			isOpen={showModal}
			setIsOpen={setShowModal}
			heading={`KPI Performance ${toSentenceCase(name)}`}>
			{rowData && (
				<div style={{ width: "100%", height: "480px" }}>
					<DataTable
						columnDefs={colDefs}
						rowData={rowData}
						sizeToFit
						domLayout="autoHeight"
					/>
				</div>
			)}
		</Modal>
	);
}

export default DataPopover;
