import React, { useState } from 'react';
import PublicMap from "./map";
import { Layers, TileLayer, VectorLayer } from "./layers";

import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { osm, vector } from "./source";
import { fromLonLat, get } from 'ol/proj';
import { Controls, FullScreenControl } from "./controls";


let styles = {
  'MultiPolygon': new Style({
    stroke: new Stroke({
      color: 'blue',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)',
    }),
  }),
};
const ImpactMap = () => {
  const [center, setCenter] = useState([-94.9065, 38.9884]);
  const [zoom, setZoom] = useState(9);
  const [showLayer1, setShowLayer1] = useState(true);
  const [showLayer2, setShowLayer2] = useState(true);




return (
  <div>
    <PublicMap center={fromLonLat(center)} zoom={zoom}>
      <Layers>
        <TileLayer
          source={osm()}
          zIndex={0}
        />
        {/* {showLayer1 && (

        )}
        {showLayer2 && (
          
        )} 
          {showMarker && <VectorLayer source={vector({ features })} />}
      */}
      </Layers>
      <Controls>
        <FullScreenControl />
      </Controls>
    </PublicMap>
    <div>
      <input
        type="checkbox"
        checked={showLayer1}
        onChange={event => setShowLayer1(event.target.checked)}
      /> Arun
    </div>
    <div>
      <input
        type="checkbox"
        checked={showLayer2}
        onChange={event => setShowLayer2(event.target.checked)}
      /> Manjeet</div>
    </div>
  );
}
export default ImpactMap;